module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/app/src/intl","languages":["fi","sv","en"],"defaultLanguage":"fi","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kaapelitehdas","short_name":"Kaapelitehdas","lang":"fi","start_url":"/","background_color":"#6600dd","theme_color":"#6600dd","display":"minimal-ui","icons":[{"src":"/favicons/favicon.png","sizes":"32x32","type":"image/png"}],"legacy":false,"localize":[{"name":"Kabelfabriken","short_name":"Kabelfabriken","lang":"sv","start_url":"/sv/"},{"name":"Cable Factory","short_name":"Cable Factory","lang":"en","start_url":"/en/"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-43171599-1","anonymize":true},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
